import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Card = styled.div`
  width: 1024px;
  opacity: 1;
  border-radius: 20px;
  padding: 34px;
  margin: 0 auto;
  
  .banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    .title {
      position: relative;
      font-size: 36px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: normal;
      margin-top: 20px;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        content: attr(data-subtitle);
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }

    .img {
      width: 207px;
      height: 153px;
    }
  }
  
  .content {
    opacity: 1;
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    line-height: 1.5;
  }
  
  .bold {
    font-weight: 600;
  }
  
  .indent {
    text-indent: 36px;
  }
`


const Join = () => (
  <Layout>
    <SEO title="商家入驻" />

    <Card>
      <div className="banner">
        <div className="title" data-subtitle="优质平台，值得您的加入！">面向全国招募商家</div>
        <img className="img" src={require('../images/speak.svg').default}/>
      </div>
      <div className="content">
        <p className="bold">入驻优势</p>
        <ol>
          <li>平台专注宠物活体交易，高效、专业、精准。</li>
          <li>平台覆盖全国用户，让您足不出户全国开单。</li>
          <li>在线发布宠物，无中间环节，买卖双方直接对接，交易过程简单高效。</li>
          <li>平台开放招募期间，入驻商家免费享受会员权益，免发布费。</li>
        </ol>

        <p className="bold">入驻标准</p>
        <ol>
          <li>遵守平台商家规范，接受平台质保要求，为消费者提供至少10天健康担保。</li>
          <li>缴纳2000元人民币担保金作为信誉担保。</li>
          <li>接受安排专人实地考察。</li>
          <li>接受平台不定期对话回访，提供宠物免疫、驱虫等健康证明。</li>
        </ol>

        <p className="bold">入驻流程</p>
        <ol>
          <li>下载买只宠物APP，注册登录，进入我的页面进行商家认证。</li>
          <li>在线缴纳保证金后即可开通店铺，</li>
        </ol>
      </div>
    </Card>
  </Layout>
)

export default Join
